<template>
  <PagesIndexSlugComponent
    v-if="data"
    :data="data"
    :prev="prev"
    :next="next"
  />
</template>

<script lang="ts" setup>
// 1
const queryPath = queryPageEntryBySlug(baseStrapiGetSlugQueryObjectFromRoute())
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPath } }
  )
})

basePageSetHead(data)

// 2
const title = baseStrapiGetAttributes(data)?.title || null

const prevQueryOptions = baseStrapiGetPrevNextQueryObject(title, '$gt', 'asc')
const { data: prev } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPageEntryPrevNextBySlug(prevQueryOptions) } }
  )
})

const nextQueryOptions = baseStrapiGetPrevNextQueryObject(title, '$lt', 'desc')
const { data: next } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPageEntryPrevNextBySlug(nextQueryOptions) } }
  )
})
</script>

<!-- if no result push to not found -->

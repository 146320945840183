import qs from 'qs'

const fields = [
  'title',
  'slug'
]

export const queryPageEntryBySlug = (options: QueryBySlugOrIdOptions) => {
  const query = qs.stringify(
    {
      publicationState: options.publicationState || 'live',
      filters: {
        slug: {
          $eq: options.slug
        }
      },
      fields,
      populate: {
        ...queryFragmentSeo,
        ...queryFragmentBlocks
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `page-entries?${query}`
}

import qs from 'qs'

export const queryPageEntryPrevNextBySlug = (options: QueryBySlugPrevNextOptions) => {
  const query = qs.stringify(
    {
      sort: [`title:${options?.sortDir}`],
      pagination: {
        page: 1,
        pageSize: 1
      },
      filters: {
        title: {
          [options?.filter]: options?.filterValue
        }
      },
      fields: queryFieldsPrevNext
    },
    {
      encodeValuesOnly: true
    }
  )

  return `page-entries?${query}`
}

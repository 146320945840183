<template>
  <LayoutModalComponent
    v-if="page"
    :close-path="closePath"
  >
    <template #header>
      <PagesIndexSlugHeaderComponent
        :page="page"
      />
    </template>

    <CoreBlocksComponent
      :blocks="page?.blocks"
    />

    <template #footer>
      <PagesNewsSlugPrevNextPageComponent
        :prev="attributes(prev)"
        :next="attributes(next)"
      />
    </template>
  </LayoutModalComponent>
</template>

<script lang="ts" setup>
const attributes = baseStrapiGetAttributes

const props = defineProps({
  data: {
    type: Object
  },
  slugPages: {
    type: Array
  },
  prev: {
    type: Object,
    required: false
  },
  next: {
    type: Object,
    required: false
  }
})

const page = baseStrapiGetAttributes(props.data)

const nuxtApp = useNuxtApp()
const closePath = getClosePath('index')
</script>
